import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import HaveQuestions from '../components/StillHaveQuestions'
import ContactAnimation from '../components/ContactAnimation'
import GiftBoxAnimation from '../components/GiftBoxAnimation'
import TelephoneAnimation from '../components/TelephoneAnimation'

const ShoppingThankYouPage = () => {
  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo title="THANK YOU" noindex={true} />

      <section className="w-10/12 mx-auto max-w-[1366px] py-10 text-center text-lg">
        <h1 className="font-black text-3xl uppercase text-teal-600">
          {' '}
          Your EZ Ship Box request has been placed!
        </h1>
        <p className="text-teal-600 font-bold uppercase">Thank you for your request!</p>
        <h2 className="py-10 uppercase text-gray-500 text-2xl">What happens next?</h2>
        <ul className="grid grid-cols-1 lg:grid-cols-1 text-base">
          <li className="w-full mx-auto max-w-[400px] px-2">
            <div className="w-full aspect-square relative p-4">
              <div className="bg-gray-200 aspect-square rounded-full w-10/12 top-0 left-0 translate-x-[8.33%] translate-y-[8.33%] absolute -z-10"></div>
              <GiftBoxAnimation />
            </div>
            Your request will be processed soon! Please be sure to check your email for request details
            and confirmation.
          </li>
        </ul>
        <hr className="mt-10" />
        <HaveQuestions />
      </section>
      <Map />
    </Layout>
  )
}

export default ShoppingThankYouPage
